import React, { useEffect, useRef, useState } from "react";
import { Locale } from "@travellocal/utils";
import { useRouter } from "next/router";

interface TrustpilotProps {
  locale: Locale;
  template: "mini" | "carousel" | "grid";
  height?: string; // templates have min height, check Trustpilot documentation
  filters?: { [key in `data-${string}`]: string };
  theme?: "light" | "dark";
}

const localesMap: Record<Locale, string> = {
  en: "en-US",
  "en-gb": "en-GB",
  de: "de-DE",
} as const;

const reviewLanguagesMap = {
  en: "en",
  "en-gb": "en",
  de: "de",
} as const;

const templateIds = {
  mini: "53aa8807dec7e10d38f59f32",
  carousel: "53aa8912dec7e10d38f59f36",
  grid: "539adbd6dec7e10e686debee",
};

const urls = {
  en: "https://www.trustpilot.com/review/travellocal.com",
  "en-gb": "https://uk.trustpilot.com/review/travellocal.com",
  de: "https://de.trustpilot.com/review/travellocal.com",
};

export const Trustpilot: React.FC<TrustpilotProps> = ({
  locale,
  template,
  filters,
  theme = "light",
  height,
}) => {
  const router = useRouter();
  const trustboxRef = useRef<HTMLDivElement>(null);
  const [currentPath, setCurrentPath] = useState("");
  const tpLocale = localesMap[locale];
  const reviewLanguages = reviewLanguagesMap[locale];
  const templateId = templateIds[template];
  const widgetHeight = height || (template === "grid" ? "400px" : "140px");
  const url = urls[locale];

  useEffect(() => {
    setCurrentPath(router.asPath);

    const handleRouteChange = (url: string) => {
      setCurrentPath(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    if (trustboxRef.current && "Trustpilot" in window) {
      (
        window as Window & {
          Trustpilot: { loadFromElement: (element: HTMLElement, resizeIframe: boolean) => void };
        }
      ).Trustpilot.loadFromElement(trustboxRef.current, true);
    }
    // tpLocale is not used here but is required to make the Trustpilot script reload
  }, [trustboxRef, tpLocale, currentPath]);

  return (
    <div
      ref={trustboxRef}
      className="trustpilot-widget"
      data-locale={tpLocale}
      data-chromatic="ignore"
      data-review-languages={reviewLanguages}
      data-template-id={templateId}
      data-businessunit-id="5cc057cf001a030001a845d2"
      data-style-height={widgetHeight}
      data-style-width="100%"
      data-font-family="Muli"
      data-stars="4,5"
      data-theme={theme}
      {...filters}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  );
};
