import React from "react";
import { GetPersonalizedQuote } from "./GetPersonalizedQuote";
import { PriceCard } from "./PriceCard";
import { TopInfo } from "./TopInfo";
import "./Pricing.scss";

interface Pricing {
  country: string;
  minPrice: string;
  maxPrice: string;
  ctaUrl: string;
  dataTrackingId: string;
}

export const Pricing = ({ country, minPrice, maxPrice, ctaUrl, dataTrackingId }: Pricing) => {
  return (
    <div className="price-guide-wrapper">
      <div>
        <TopInfo country={country} />
        <GetPersonalizedQuote
          className="is-hidden-touch"
          ctaUrl={ctaUrl}
          dataTrackingId={dataTrackingId}
        />
      </div>
      <div>
        <PriceCard minPrice={minPrice} maxPrice={maxPrice} />
      </div>
      <GetPersonalizedQuote
        className="is-hidden-desktop"
        ctaUrl={ctaUrl}
        dataTrackingId={dataTrackingId}
      />
    </div>
  );
};
