import React, { useCallback } from "react";
import { Icomoon, Icon, Link, Stamp } from "../../atoms";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";
import { LtoImages } from "./LtoImages";
import { getPartnerProfilePics } from "../../utils/getPartnerProfilePics";
import "./CountryHeader.scss";

interface Lto {
  profileImageUrl: string;
  profileImageUrlAlt: string;
}

interface Props {
  title: string;
  subtitle: string;
  country: string;
  findOutHowCta?: string;
  scrollToId?: string;
  ltos: Array<Lto>;
  countryImage: string;
  countryImageAlt: string;
}

export const CountryHeader: React.FC<Props> = ({
  title,
  subtitle,
  country,
  findOutHowCta = `Find out how`,
  scrollToId,
  ltos,
  countryImage,
  countryImageAlt,
}) => {
  const [ltoToUse, setLtosToUse] = React.useState<Array<Lto>>(ltos.slice(0, 3));

  React.useEffect(() => {
    if (ltoToUse.length < 3) {
      const backupProfilePics = getPartnerProfilePics(3 - ltoToUse.length);
      const profilePicsToShow = ltoToUse.concat(
        backupProfilePics.map((imageUrl, index) => {
          return {
            profileImageUrl: imageUrl,
            profileImageUrlAlt: `LTO ${index}`,
          };
        })
      );
      setLtosToUse(profilePicsToShow);
    }
  }, [ltos]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (!scrollToId) {
        return;
      }
      const element = document.getElementById(scrollToId);

      if (element) {
        window.scrollTo({
          top: element.offsetTop - 100,
          behavior: "smooth",
        });
      }
    },
    [scrollToId]
  );

  return (
    <div className="country-header">
      <div className="text-info">
        <h1
          className="country-text has-text-weight-normal is-family-secondary has-text-navy"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className="connect-text has-text-weight-normal is-family-primary has-text-elephant"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
        {scrollToId && (
          <div className="link-wrapper has-text-weight-normal is-family-primary is-flex is-align-items-center">
            <Link onClick={handleClick} className="link" href="#">
              <span>{findOutHowCta}</span>
              <Icon icomoon={Icomoon.arrowDown3} className="pl-2 arrow-icon" />
            </Link>
          </div>
        )}
      </div>
      <div className="side-wrapper">
        <div className="country-image-wrapper">
          <Image
            src={countryImage}
            alt={countryImageAlt}
            loader={imageLoader}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
          <Stamp type="light" className="stamp" />
        </div>
        <LtoImages className="lto-images" country={country} ltos={ltoToUse} />
      </div>
    </div>
  );
};
