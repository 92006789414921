import React from "react";
import Image from "next/legacy/image";
import imageLoader from "../../../utils/imageLoader";
import "./LtoImages.scss";

interface LtoImagesProps {
  ltos: {
    profileImageUrl: string;
    profileImageUrlAlt: string;
  }[];
  country: string;
  className?: string;
}

export const LtoImages: React.FC<LtoImagesProps> = ({ className = "", country, ltos }) => {
  return (
    <div className={`lto-images-int ${className}`}>
      <div className="flex is-flex-direction-column is-align-items-center">
        <div>
          {ltos.map((item, index) => (
            <span className="avatar" key={index}>
              <Image
                src={item.profileImageUrl}
                alt={item.profileImageUrlAlt}
                layout="fill"
                objectFit="cover"
                loader={imageLoader}
              />
            </span>
          ))}
        </div>
        <h2 className="lto-title subtitle is-family-primary is-letter-spacing-1 is-uppercase">
          Our Local Agents in {country}
        </h2>
      </div>
    </div>
  );
};
