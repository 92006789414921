import React from "react";
import "./Header.scss";

export const Header = () => {
  return (
    <div className="price-card-header-wrapper">
      <div className="header-item">
        <svg
          width="29"
          height="25"
          viewBox="0 0 29 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.4101 6.23913C13.4101 8.87609 11.3216 10.9783 8.78857 10.9783C6.25555 10.9783 4.16699 8.87609 4.16699 6.23913C4.16699 3.60217 6.25555 1.5 8.78857 1.5C11.3216 1.5 13.4101 3.60217 13.4101 6.23913Z"
            stroke="#13294B"
            strokeWidth="2"
          />
          <path
            d="M1.12305 24.4998C2.40723 21.0216 5.74611 14.0651 8.82814 14.0651C11.9102 14.0651 14.5152 21.0216 15.4325 24.4998"
            stroke="#13294B"
            strokeWidth="2"
          />
          <path
            d="M25.9876 8.62116C25.9876 10.8447 24.2273 12.6117 22.0993 12.6117C19.9712 12.6117 18.2109 10.8447 18.2109 8.62116C18.2109 6.39763 19.9712 4.63062 22.0993 4.63062C24.2273 4.63062 25.9876 6.39763 25.9876 8.62116Z"
            stroke="#13294B"
            strokeWidth="2"
          />
          <path
            d="M15.4336 24.4999C16.5503 21.4754 19.4536 15.4262 22.1337 15.4262C24.8137 15.4262 27.079 21.4754 27.8766 24.4999"
            stroke="#13294B"
            strokeWidth="2"
          />
        </svg>
        <span>2 travelers</span>
      </div>
      <div className="header-item">
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.7803 22.5C16.8567 22.5 19.678 21.1014 21.5476 18.7979C22.6519 17.4373 21.4477 15.4493 19.741 15.7744C16.5243 16.387 13.5585 13.928 13.5585 10.6653C13.5585 8.77375 14.5765 7.06016 16.1927 6.14047C17.7061 5.27914 17.3254 2.98469 15.6059 2.66707C15.0037 2.55602 14.3926 2.5001 13.7803 2.5C8.2584 2.5 3.78027 6.97559 3.78027 12.5C3.78027 18.0219 8.2559 22.5 13.7803 22.5ZM13.7803 4.375C14.2875 4.375 14.7837 4.42191 15.2653 4.51086C13.1262 5.72816 11.6835 8.02812 11.6835 10.6653C11.6835 15.1125 15.732 18.4466 20.0918 17.6163C18.6021 19.4517 16.3284 20.625 13.7803 20.625C9.29297 20.625 5.65527 16.9873 5.65527 12.5C5.65527 8.0127 9.29297 4.375 13.7803 4.375Z"
            fill="#13294B"
          />
        </svg>
        <span>7 nights</span>
      </div>
    </div>
  );
};
